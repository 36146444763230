import type { IDropdownItem } from "@business-finland/wif-ui-lib";
import type { IJobCardProps } from "@business-finland/wif-ui-lib/dist/JobList";
import { useCallback, useMemo } from "react";

import { ONE_MIN_IN_MS, ONE_SEC_IN_MS } from "../../../../constants/durations";
import useQuery from "../../../../hooks/useQuery";
import { OpenJobsDispatch, OpenJobsState } from "./store";

export default function useOpenJobsSearch(
  state: OpenJobsState,
  dispatch: OpenJobsDispatch
): void {
  const {
    query = "",
    page = 1,
    selectedCategories = [],
    selectedCities = [],
  } = state;

  const searchQuery: string = useMemo(() => {
    const searchParams = new URLSearchParams({
      limit: "12",
      page: page.toString(),
    });

    if (query) searchParams.set("query", query);
    if (selectedCategories.length > 0)
      searchParams.set("category", selectedCategories.join(","));
    if (selectedCities.length > 0)
      searchParams.set("city", selectedCities.join(","));

    return searchParams.toString();
  }, [query, page, selectedCategories, selectedCities]);

  const handleSuccess = useCallback(
    (result: Jobs.All.IJobResult) => {
      dispatch((state) => ({
        isError: false,
        totalJobs: result.totalJobs ?? 0,
        totalPages: result.totalPages ?? 0,
        jobCards: result.jobs.map(mapJobRecordToJobCardProps),
        cities: getUpdatedDropdownItems(
          state.cities,
          state.selectedCities,
          result.cities
        ),
        categories: getUpdatedDropdownItems(
          state.categories,
          state.selectedCategories,
          result.categories
        ),
      }));
    },
    [dispatch]
  );

  const handleError = useCallback(() => {
    dispatch({ isError: true, jobCards: [], totalJobs: 0, totalPages: 0 });
  }, [dispatch]);

  const handleLoading = useCallback(() => {
    dispatch({ jobCards: undefined, isError: false });
  }, [dispatch]);

  useQuery<Jobs.All.IJobResult>("/api/jobs/?" + searchQuery, {
    cacheDurationInMs: ONE_MIN_IN_MS * 10,
    debounceDurationInMs: ONE_SEC_IN_MS,
    onSuccess: handleSuccess,
    onError: handleError,
    onLoading: handleLoading,
  });
}

function mapJobRecordToJobCardProps(
  job: Jobs.All.IJobRecordResult
): IJobCardProps {
  return {
    title: job.title,
    employerName: job.employer.name,
    location: job.employer.city,
    link: job.externalUrl,
    image: job.employer.imageUrl,
    expireDate: job.expireDate,
    isDataLoading: false,
  };
}

function getUpdatedDropdownItems(
  previousItems: IDropdownItem[],
  selectedItemValues: string[],
  newCategoriesOrCities: Jobs.All.ICategoryCityItem[]
): IDropdownItem[] {
  const newItems = newCategoriesOrCities.map(mapCategoryCityItemToDropdownItem);
  // If there are no previous items, then return new items.
  if (previousItems.length === 0) return newItems;
  // If there are no selected items, then return new items.
  // else stick with previous items.
  return selectedItemValues.length === 0 ? newItems : previousItems;
}

function mapCategoryCityItemToDropdownItem(
  item: Jobs.All.ICategoryCityItem
): IDropdownItem {
  return {
    label: item.name,
    value: item.id,
    count: item.count,
  };
}
