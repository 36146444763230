import {
  IDropdownItem,
  ITypeAheadProps,
  ITypeAheadSearchOption,
} from "@business-finland/wif-ui-lib";
import {
  JobsHeader,
  IJobsFilter,
} from "@business-finland/wif-ui-lib/dist/JobsHeader";
import { useCallback, useMemo, useState } from "react";

import { ONE_DAY_IN_MS, ONE_MIN_IN_MS } from "../../../../constants/durations";
import useQuery from "../../../../hooks/useQuery";
import { useOpenJobsDispatch, useOpenJobsState } from "./context";
import { ACTIONS } from "./store";

export interface IOpenJobsHeaderProps extends Partial<Wif.Mgnl.OpenJobsPage> {}

export default function OpenJobsHeader(
  props: IOpenJobsHeaderProps
): JSX.Element {
  const {
    heading = "Find a job in Finland",
    leadText = "Explore open jobs for English-speaking professionals.",
  } = props;

  const dispatch = useOpenJobsDispatch();

  const citiesFilter = useCitiesFilter();
  const categoriesFilter = useCategoriesFilter();
  const searchTypeAhead = useSearchTypeAhead();

  const handleOpen = useCallback(
    (open: boolean) => {
      dispatch({ isCurtainVisible: open });
    },
    [dispatch]
  );

  return (
    <JobsHeader
      heading={heading}
      leadText={leadText}
      categories={categoriesFilter}
      cities={citiesFilter}
      search={searchTypeAhead}
      onDropdownOpenChange={handleOpen}
    />
  );
}

function useCitiesFilter(): IJobsFilter {
  const { selectedCities, cities } = useOpenJobsState();
  const dispatch = useOpenJobsDispatch();

  const handleSelectedCitiesChange = useCallback(
    (items: IDropdownItem[]) => {
      const selectedCities: string[] = items.map((item) => item.value);
      dispatch(ACTIONS.updateStateWithPageReset({ selectedCities }));
    },
    [dispatch]
  );

  const selectedCitiesDropdownItems = useMemo(() => {
    return cities.filter((city) => selectedCities.includes(city.value));
  }, [cities, selectedCities]);

  return {
    items: cities,
    onSelectedItemsChange: handleSelectedCitiesChange,
    selectedItems: selectedCitiesDropdownItems,
  };
}

function useCategoriesFilter(): IJobsFilter {
  const { selectedCategories, categories } = useOpenJobsState();
  const dispatch = useOpenJobsDispatch();

  const handleSelectedCategoriesChange = useCallback(
    (items: IDropdownItem[]) => {
      const selectedCategories: string[] = items.map((item) => item.value);
      dispatch(ACTIONS.updateStateWithPageReset({ selectedCategories }));
    },
    [dispatch]
  );

  const selectedCategoriesDropdownItems = useMemo(() => {
    return categories.filter((city) => selectedCategories.includes(city.value));
  }, [categories, selectedCategories]);

  return {
    items: categories,
    onSelectedItemsChange: handleSelectedCategoriesChange,
    selectedItems: selectedCategoriesDropdownItems,
  };
}

function useSearchTypeAhead(): ITypeAheadProps<ITypeAheadSearchOption> {
  const { query, searchHistory } = useOpenJobsState();
  const dispatch = useOpenJobsDispatch();

  const searchRecommendations = useSearchRecommendations();
  const { searchAutocompleteSuggestion, onCurrentWordChange } =
    useSearchAutocompleteSuggestion();

  const handleSearch = useCallback(
    (query: string) => dispatch((state) => ACTIONS.search(query.trim(), state)),
    [dispatch]
  );

  const handleClearSearch = useCallback(
    () => dispatch((state) => ACTIONS.search("", state)),
    [dispatch]
  );

  const handleRecommendationClick = useCallback(
    (item: ITypeAheadSearchOption) => {
      const value = typeof item === "string" ? item : item.value;
      dispatch(
        ACTIONS.updateStateWithPageReset({
          selectedCategories: [value],
          selectedCities: [],
          query: "",
        })
      );
    },
    [dispatch]
  );

  return {
    defaultValue: query,
    onSearch: handleSearch,
    onCurrentWordChange,
    onClear: handleClearSearch,
    onSearchRecommendationClick: handleRecommendationClick,
    searchAutocompleteSuggestion,
    searchHistory,
    searchRecommendations,
  };
}

function useSearchRecommendations(): ITypeAheadSearchOption[] {
  const { data } = useQuery<Jobs.Status.Result>("/api/jobs/status", {
    cacheDurationInMs: ONE_DAY_IN_MS,
    initialData: { totalJobs: 0, categories: [] },
  });

  return (data.categories || [])
    .map((category) => ({
      label: category.name,
      value: category.id,
    }))
    .slice(0, 5);
}

function useSearchAutocompleteSuggestion() {
  const [currentWord, setCurrentWord] = useState("");

  const {
    data: { suggestions: searchAutocompleteSuggestion },
  } = useQuery<Jobs.Autocomplete.Result>(
    "/api/jobs/autocomplete?word=" + currentWord,
    {
      cacheDurationInMs: ONE_MIN_IN_MS * 10,
      enableBackgroundFetch: true,
      initialData: { suggestions: [], limit: 0, offset: 0, total: 0 },
    }
  );

  return { searchAutocompleteSuggestion, onCurrentWordChange: setCurrentWord };
}
