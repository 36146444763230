import { createContext, useContext } from "react";

import { openJobsInitialState, OpenJobsDispatch } from "./store";

export const OpenJobsStateContext = createContext(openJobsInitialState);

export const OpenJobsDispatchContext = createContext<OpenJobsDispatch>(
  (state) => state
);

export function useOpenJobsState() {
  return useContext(OpenJobsStateContext);
}

export function useOpenJobsDispatch() {
  return useContext(OpenJobsDispatchContext);
}
