import type { IDropdownItem } from "@business-finland/wif-ui-lib";
import type { IJobCardProps } from "@business-finland/wif-ui-lib/dist/JobList";

import { LOCAL_STORAGE_JOBS_SEARCH_HISTORY } from "../../../../constants/localStorageKeys";

export interface OpenJobsState {
  isCurtainVisible: boolean;
  isError: boolean;
  totalJobs: number;
  totalPages: number;
  jobCards?: IJobCardProps[];
  query: string;
  page: number;
  cities: IDropdownItem[];
  selectedCities: string[];
  categories: IDropdownItem[];
  selectedCategories: string[];
  searchHistory: string[];
}

export type OpenJobsDispatchPayload =
  | Partial<OpenJobsState>
  | ((state: OpenJobsState) => Partial<OpenJobsState>);
export type OpenJobsDispatch = React.Dispatch<OpenJobsDispatchPayload>;

export const openJobsInitialState: OpenJobsState = {
  isCurtainVisible: false,
  isError: false,
  totalJobs: 0,
  totalPages: 0,
  jobCards: [],
  query: "",
  page: 1,
  categories: [],
  selectedCategories: [],
  cities: [],
  selectedCities: [],
  searchHistory: [],
};

export function openJobsStateReducer(
  state: OpenJobsState,
  payload: OpenJobsDispatchPayload
): OpenJobsState {
  const payloadState = typeof payload === "function" ? payload(state) : payload;
  const newState = { ...state, ...payloadState };
  updateUrlAndLocalStorage(newState);

  return newState;
}

export const ACTIONS = {
  initState: function (): Partial<OpenJobsState> {
    if (typeof window === "undefined") return {};

    const { searchParams } = new URL(window.location.href);
    const savedHistory = window.localStorage.getItem(
      LOCAL_STORAGE_JOBS_SEARCH_HISTORY
    );
    const searchHistory: string[] = savedHistory
      ? JSON.parse(savedHistory)
      : [];

    return {
      query: searchParams.get("query") ?? "",
      page: Number(searchParams.get("page") ?? 1),
      selectedCategories: searchParams.get("category")?.split(",") || [],
      selectedCities: searchParams.get("city")?.split(",") || [],
      searchHistory,
    };
  },

  search: function (
    query: string,
    state?: OpenJobsState
  ): Partial<OpenJobsState> {
    if (!state) {
      return this.updateStateWithPageReset({ query });
    }

    const searchHistory: string[] = query
      ? state.searchHistory.includes(query)
        ? state.searchHistory
        : [query, ...state.searchHistory]
      : state.searchHistory;

    return this.updateStateWithPageReset({ query, searchHistory });
  },

  updateStateWithPageReset: function (
    newState: Partial<Omit<OpenJobsState, "page">> = {}
  ): Partial<OpenJobsState> {
    return { ...newState, page: 1 };
  },

  resetState: function (): Partial<OpenJobsState> {
    return openJobsInitialState;
  },
} as const;

function updateUrlAndLocalStorage(state: OpenJobsState) {
  if (typeof window === "undefined") return;

  // Search history in local storage
  window.localStorage.setItem(
    LOCAL_STORAGE_JOBS_SEARCH_HISTORY,
    JSON.stringify(state.searchHistory)
  );

  // URL with filters
  const url = new URL(window.location.href);

  setOrDeleteSearchParam(url, "query", state.query, !!state.query);
  setOrDeleteSearchParam(url, "page", state.page.toString(), state.page > 1);
  setOrDeleteSearchParam(
    url,
    "category",
    state.selectedCategories.join(","),
    Array.isArray(state.selectedCategories) &&
      state.selectedCategories.length > 0
  );
  setOrDeleteSearchParam(
    url,
    "city",
    state.selectedCities.join(","),
    Array.isArray(state.selectedCities) && state.selectedCities.length > 0
  );

  window.history.replaceState({}, "", url);
}

function setOrDeleteSearchParam(
  url: URL,
  name: string,
  value: string,
  condition: boolean
) {
  if (condition) {
    url.searchParams.set(name, value);
  } else {
    url.searchParams.delete(name);
  }
}
