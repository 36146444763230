import {
  BlockPadding,
  Curtain,
  ErrorHandler,
} from "@business-finland/wif-ui-lib";
import { SectionHeader } from "@business-finland/wif-ui-lib/dist/SectionHeader";
import { JobList } from "@business-finland/wif-ui-lib/dist/JobList";
import { Pagination } from "@business-finland/wif-ui-lib/dist/Pagination";
import { RefObject, useEffect, useReducer, useRef } from "react";

import {
  OpenJobsStateContext,
  OpenJobsDispatchContext,
  useOpenJobsState,
  useOpenJobsDispatch,
} from "./context";
import { ACTIONS, openJobsInitialState, openJobsStateReducer } from "./store";
import OpenJobsHeader from "./OpenJobsHeader";
import useOpenJobsSearch from "./useOpenJobsSearch";
import {
  headerHeightSmAndAbove,
  headerHeightXs,
  Viewport,
} from "../../../../constants/app";
import useViewport from "../../../../contexts/ViewportContext";

export interface IOpenJobsProps extends Wif.Mgnl.OpenJobsPage {}

export default function OpenJobs(props: IOpenJobsProps): JSX.Element {
  const [state, dispatch] = useReducer(
    openJobsStateReducer,
    openJobsInitialState
  );

  useEffect(() => {
    dispatch(ACTIONS.initState());
  }, [dispatch]);

  useOpenJobsSearch(state, dispatch);

  const jobCardsSectionRef = useRef<HTMLDivElement>(null);

  const { isError, isCurtainVisible, totalJobs, jobCards } = state;

  if (isError) {
    return <OpenJobsErrorFallback />;
  }

  return (
    <OpenJobsDispatchContext.Provider value={dispatch}>
      <OpenJobsStateContext.Provider value={state}>
        <div style={{ backgroundColor: "#F5F5F5" }}>
          <OpenJobsHeader {...props} />

          <div
            ref={jobCardsSectionRef}
            style={{ position: "relative", zIndex: 0 }}
          >
            <Curtain show={isCurtainVisible} />

            <div style={{ visibility: jobCards ? "visible" : "hidden" }}>
              <SectionHeader>
                {totalJobs > 0
                  ? `There are ${totalJobs} open jobs right now`
                  : "0 jobs available"}
              </SectionHeader>
            </div>

            <JobList bottomPadding={BlockPadding.SMALL} jobCards={jobCards} />

            <OpenJobsPagination jobCardsSectionRef={jobCardsSectionRef} />
          </div>
        </div>
      </OpenJobsStateContext.Provider>
    </OpenJobsDispatchContext.Provider>
  );
}

function OpenJobsPagination(props: {
  jobCardsSectionRef: RefObject<HTMLDivElement>;
}): JSX.Element | null {
  const { totalPages, page } = useOpenJobsState();
  const dispatch = useOpenJobsDispatch();
  const viewport = useViewport();

  const scrollToJobCardsStart = () => {
    if (props.jobCardsSectionRef.current) {
      const offsetJobCardSection = props.jobCardsSectionRef.current.offsetTop;
      const headerHeight =
        viewport === Viewport.xs ? headerHeightXs : headerHeightSmAndAbove;

      const offsetTotal = offsetJobCardSection - headerHeight;

      window.scrollTo(0, offsetTotal);
    }
  };
  const onPagination = (page: number) => {
    dispatch({ page });
    scrollToJobCardsStart();
  };

  if (totalPages === 0) {
    return null;
  }

  return <Pagination count={totalPages} onChange={onPagination} value={page} />;
}

function OpenJobsErrorFallback(): JSX.Element {
  return (
    <ErrorHandler
      title={"Oops! Something's missing"}
      description={
        "The page you're looking for is currently unavailable. Try hitting refresh or returning to the homepage."
      }
      videoSrc={"/static/videos/Icon_Robot.mp4"}
      fallbackSrc={"/static/images/Icon_Robot.gif"}
    />
  );
}
