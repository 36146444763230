import dynamic from "next/dynamic";
import Head from "next/head";
import React, { Suspense } from "react";

import { META_TITLE_SUFFIX } from "../../../constants/app";
import OpenJobs from "./OpenJobs";

const EditableArea = dynamic(
  () => import("../../../components/MgnlEditor/EditableArea")
);

interface MagnoliaTemplatePageProps extends Wif.Mgnl.OpenJobsPage {
  children?: React.ReactNode;
}

export function OpenJobsPage({
  children,
  ...props
}: MagnoliaTemplatePageProps): JSX.Element | null {
  const { title, metaDescription, main } = props;

  return (
    <>
      <Head>
        <title>{title + META_TITLE_SUFFIX}</title>
        <meta name="description" content={metaDescription} />
      </Head>

      <OpenJobs {...props} />

      {children ? (
        <div>{children}</div>
      ) : main ? (
        <Suspense>
          <EditableArea content={main} className="EditableArea" />
        </Suspense>
      ) : null}
    </>
  );
}
